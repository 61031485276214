import { createApp } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import router from '@/router';
import baseUrl from '@/services/Api/BaseUrl';
import NavbarSection from '@/components/Navbar/NavbarSection.vue';
import { createI18n } from 'vue-i18n'
import App from './App.vue';
import store from './store';
import ARG from '@/assets/lang/es.json'
import BRA from '@/assets/lang/pr.json'
const i18n = createI18n({
  // locale:  window.navigator.language.split('-')[0], // if you want the app to detect the browser's default language
  legacy: false,
  locale: 'arg', // default language
  fallbackLocale: 'arg', // fallback language in case a translation is not found
  messages: {
    bra: BRA,
    arg: ARG,
  },
})
import './assets/tailwind.css';

import { icons } from './assets/icons/fontAwesome';

library.add(icons);
const app = createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('NavbarSection', NavbarSection)
  .use(store)
  .use(router)
  .use(i18n)
  .mount('#app');


app.$.appContext.config.globalProperties.$apiDevuelvoya = baseUrl.apiDevuelvoya;
